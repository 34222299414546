<script>
export default {
    name: "changeType",
    props: ["user", "id"],
    data: () => ({
        dialog: false,
        loading: false,
        isInvestor: false,
        valid: true,
        userType: [
            { text: "شركاء", value: true },
            { text: "مستخدمين", value: false },
        ],
        body: {
            type: 0,
        },
    }),
    methods: {
        closeDown() {
            this.dialog = false;
            this.reset();
            this.resetValidation();
        },
        openDialog() {
            const isInvestor = this.user?.type !== 1;
            // console.log(this.user?.type,isInvestor);
            this.body.type = isInvestor;
        },
        async submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.loading = true;
                await this.$http
                    .put(
                        `${this.$route.meta.endPoint}/changeUserType/${this.id}?isInvestor=${this.body.type}`
                    )
                    .then((res) => {
                        this.$emit(`refresh`);
                        this.closeDown();
                        this.$store.dispatch("toastification/setToast", {
                            message: `${res.data.message}`,
                            type: "success",
                        });
                    })
                    .catch((err) => {
                        this.$store.dispatch("toastification/setToast", {
                            message: `${err.response.data.message}`,
                            type: "error",
                        });
                    })
                    .finally(() => (this.loading = false));
            }
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
        reset() {
            this.body = {
                type: 1,
            };
        },
    },
};
</script>

<template>
    <div>
        <v-dialog v-model="dialog" width="50%" persistent transition="dialog-bottom-transition">
            <template v-slot:activator="{ on, attrs }">
                <v-icon @click="openDialog()" v-bind="attrs" v-on="on">
                    mdi-account-star
                </v-icon>
            </template>
            <v-card flat class="px-4 pt-4">
                <!-- dialog toolbar -->
                <v-toolbar height="45" flat>
                    <h5>
                        تغير نوع ال
                        {{ this.$route.meta.single }}
                    </h5>
                    <v-spacer />

                    <v-btn @click="closeDown()" fab small icon color="grey">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider class="mx-4" />

                <!-- form body -->
                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card flat class="py-4 px-1">
                            <v-row>
                                <!-- provinceId -->
                                <v-col cols="12" md="12">
                                    <h6 class="mb-1">النوع <span class="required">*</span></h6>
                                    <v-radio-group v-model="body.type" mandatory>
                                        <v-radio label="شريك" :value="true"></v-radio>
                                        <v-radio label="مستخدم" :value="false"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>

                            <v-divider class="ml- mt-8" />
                            <!-- actions -->
                            <div class="mt-4">
                                <v-btn type="submit" :loading="loading" color="primary" class="btn" :disabled="!valid">
                                    حفظ
                                    <v-icon small right>fi fi-rr-disk</v-icon>
                                </v-btn>
                                <v-btn @click="closeDown()" text depressed class="mr-2 btn">إلغاء</v-btn>
                            </div>
                        </v-card>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped></style>